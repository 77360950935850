<template>
  <card type="user">
    <p class="card-text">
    </p>
    <div class="author">
      <div class="block block-one"></div>
      <div class="block block-two"></div>
      <div class="block block-three"></div>
      <div class="block block-four"></div>
      <a href="#">
       <img class="avatar" src="https://img.icons8.com/fluency/96/000000/user-male-circle.png"/>
        <h5 class="title text-body">{{driverDetalhes.nome}}</h5>

      </a>

      <h6 class=" text-info">{{driverDetalhes.phone}}</h6>

    </div>
    <div slot="footer" class="button-container">
       <div class="row col-12">
          <div class="col-6">
            <p class="card-description text-body">
              <strong>DADOS DO EXECUTIVO</strong>
            </p>
            <table  border="1" class="table  table-sm table-responsive-sm  text-left">
            <tbody>
              <tr>
                <th scope="row">Nome</th>
                <td >{{driverDetalhes.nome}}</td>
              </tr>
              <tr>
                <th scope="row">Email</th>
                <td>{{driverDetalhes.email}}</td>
              </tr>
              <tr>
                <th scope="row">Contacto</th>
                <td>{{driverDetalhes.phone}}</td>
              </tr>
                <tr>
                <th scope="row">NIF</th>
                <td>{{driverDetalhes.nif}}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="col-6">
          <p class="card-description text-body">
            <strong>DADOS DO VEÍCULO</strong>
          </p>
          <table border="1" class="table table-sm table-responsive-sm  text-left">
            <tbody >
              <tr  >
                <th scope="row">Marca</th>
                <td>{{driverDetalhes.modelo}} - {{driverDetalhes.marca}} - {{driverDetalhes.cor}}</td>
              </tr>
              <tr>
                <th scope="row">Placa</th>
                <td>{{driverDetalhes.placa}}</td>
              </tr>
              <tr>
                <th scope="row">Ano</th>
                <td>{{driverDetalhes.ano}}</td>
              </tr>
                <tr>
                <th scope="row">Classe</th>
                <td>{{driverDetalhes.classe}}</td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
  </card>
</template>
<script>
  import firebase from "../../firebase";

  export default {
    props: {
      user: {
        type: Object,
        default: () => {
          return {};
        }
      }
    },
    data(){
      return {
        dbDriver:firebase.ref("/motorista"),

        driverDetalhes: {
        nome:null,
        email:null,
        phone:null,
        iktoken:null,
        status:null,

        cor:null,
        ano:null,
        modelo:null,
        nif:null,
        placa:null,
        criado_em:null,
        classe:null,
        marca:null
      },
      }
    },

    methods: {
       getDriver(){
         console.log('clicado')
          this.dbDriver.child(this.$route.params.driverId).on('value',(snap)=>{
          let data = snap.val();
          //  console.log(data.perfil.uid)
          this.driverDetalhes.key = data.perfil.uid,
          this.driverDetalhes.email = data.perfil.email,
          this.driverDetalhes.nome = data.perfil.nome,
          this.driverDetalhes.phone = data.perfil.phone,
          this.driverDetalhes.token = data.perfil.token

          this.driverDetalhes.classe = data.veiculo.classe,
          this.driverDetalhes.ano = data.veiculo.ano,
          this.driverDetalhes.marca = data.veiculo.marca,
          this.driverDetalhes.modelo = data.veiculo.modelo,
          this.driverDetalhes.nif = data.veiculo.nif,
          this.driverDetalhes.placa = data.veiculo.placa
        });
      },
    },

    mounted(){
      this.getDriver()
    }
  }
</script>
<style>


</style>
