<template>
  <div class="">
    <div class="card-body all-icons">
      <div class="" style="height: 100%">
        <div class="row justify-content-center ">
          <div class="col-md-12 col-xl-12 ">
            <div class="card ">
              <div
                class="card-header msg_head"
                style="color:#000000; background-color:#ee0556;"
              >
                <div class="d-flex bd-highlight">
                  <div class="user_info">
                    <span>Editar Serviço ({{logisticaDetalhes.referencia}})</span>
                    <p></p>
                  </div>
                </div>
              </div>
              <div class="card-body msg_card_body">
                <!-- Body -->

                <div class="">
                  <div class="row">
                    <!-- Detalhes da logistica -->
                    <div class="col-md-12" v-if="isDetailLogistica == true">
                      <div>
                        <div class="row">
                          <h4 slot="header" class="col-8" style="color:#000000">
                            1. DADOS DO SERVIÇO
                          </h4>
                          <input
                            class="col-3 mt-2"
                            v-model="logisticaDetalhes.numero_pedido"
                            placeholder="Número do pedido"
                          />
                        </div>
                        <br />
                        <div class="row">
                          <base-input
                            class="col-6"
                            label="Nome do Destinatário"
                            v-model="logisticaDetalhes.destinatario"
                            placeholder="Ex: Carvalho Júlio"
                          >
                          </base-input>
                          <base-input
                            class="col-6"
                            label="E-mail do Destinatário"
                            v-model="logisticaDetalhes.email"
                            placeholder="Ex: nome@dominio.com"
                          >
                          </base-input>
                        </div>



                        <div class="row">
                          <base-input
                            class="col-6"
                            label="Data de envio"
                            v-model="logisticaDetalhes.data_envio"
                            id="id_data_de_envio"
                            type="date"
                          >
                          </base-input>

                          <base-input class="col-6" label="Periodo de Entrega">
                            <select
                              v-model="logisticaDetalhes.periodo_entrega"
                              class="form-control"
                            >
                              <option
                                v-for="(perido, index) in peridos"
                                v-bind:value="perido.value"
                                :key="index"
                              >
                                {{ perido.text }}
                              </option>
                            </select>
                          </base-input>
                        </div>

                        <div class="row">
                          <base-input class="col-6" label="Tempo de Entrega">
                            <select
                              v-model="logisticaDetalhes.tipo_entrega"
                              class="form-control"
                            >
                              <option
                                v-for="(tipo, index) in entregas"
                                v-bind:value="tipo.value"
                                :key="index"
                              >
                                {{ tipo.text }}
                              </option>
                            </select>
                          </base-input>

                          <base-input
                            label="Observações"
                            class="col-6"
                            v-model="logisticaDetalhes.observacao"
                            placeholder="Ex: Me ligue assim que...."
                          >
                          </base-input>
                        </div>
                        <!-- carregamento da nota fiscal  -->
                        <!-- <div class="row">
                          <h4><strong>Adicionar nota fiscal</strong></h4>
                          <input type="file" ref="input1"   @change="getFile" />

                        </div> -->

                        <div class="row">
                          <base-input
                            v-model="logisticaDetalhes.endereco_origem"
                            class="col-6"
                            label="Local de recolha"
                            placeholder="Ex: Rua Alfredo João..."
                            id="origin"
                          ></base-input>

                          <base-input
                           v-model="logisticaDetalhes.endereco_destino"
                            class="col-6"
                            label="Local de entrega"
                            placeholder="Ex: Rua Ribeiro..."
                            id="destin"
                          >
                          </base-input>
                        </div>

                        <base-input
                          type="hidden"
                          id="regiao"
                          disabled
                        ></base-input>

                        <div>
                          <base-button
                            type="danger"
                            @click="refresh"
                            title="Atualizar"
                            style="padding:10px; center "
                            fill
                            ><i class="fas fa-sync-alt"></i
                          ></base-button>
                          <base-button
                            type="dark"
                            title="Próximo"
                            @click="getArtigo"
                            style="padding:10px; center "
                            fill
                            >Continuar</base-button
                          >
                        </div>
                        <!-- <button @click="getSalvar">TEstar</button> -->
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12" v-if="isArtigo == true">
                    <div>
                      <h4 slot="header" style="color:#000000">
                        2. ARTIGOS DO SERVIÇO
                      </h4>

                      <div class="row" v-if="isErro == false">
                        <base-input class="col-3 mt-1" label="Quantidade">
                          <integer-plusminus
                            :min="minQtd"
                            :max="ipm_max"
                            :step="ipm_step"
                            :vertical="ipm_vertical"
                            v-model="qtd_artigo"
                          >
                          </integer-plusminus
                        ></base-input>
                        <!-- <div class="col-1"></div> -->
                        <!-- <base-input
                          class="col-6 "
                          label="Artigo"
                          v-model="artigo"
                          placeholder="Exemplo: Sofás"
                        ></base-input> -->
                        <!-- <base-input class="col-6" label="Artigo">
                            <select
                              v-model="artigo"
                              class="form-control"
                            >
                              <option
                                v-for="(tipo, index) in artigos_stock"
                                v-bind:value="tipo.value"
                                :key="index"
                              >
                                {{ tipo.text }}
                              </option>
                            </select>

                          </base-input> -->

                        <base-input
                            class="col-6"
                            label="Artigo"
                            v-model="artigo"
                            placeholder="Ex: Cama"
                          >
                          </base-input>

                        <div class="col-2">
                          <base-button
                            class="mt-4"
                            type="dark"
                            title="Adicionar"
                            @click="setArtigoToArray"
                            style="padding:10px; center "
                            fill
                            ><i class="fas fa-plus"></i
                          ></base-button>
                        </div>
                      </div>
                      <div v-if="isErro == true" class="row">
                        <base-input class="col-3 mt-1" label="Quantidade">
                          <integer-plusminus
                            :min="minQtd"
                            :max="ipm_max"
                            :step="ipm_step"
                            :vertical="ipm_vertical"
                            v-model="qtd_artigo"
                          >
                          </integer-plusminus
                        ></base-input>
                        <div class="col-1"></div>
                        <base-input
                          class="col-6"
                          label="Erro, artigo ou quantidade vazios não são aceites."
                          v-model="artigo"
                          placeholder="Exemplo: 5 Sofás"
                        ></base-input>
                        <div class="col-2">
                          <base-button
                            class="mt-4"
                            type="dark"
                            title="Adicionar"
                            @click="setArtigoToArray"
                            style="padding:10px; center "
                            fill
                            ><i class="fas fa-plus"></i
                          ></base-button>
                        </div>
                      </div>
                      <hr>
                      <div class="row">
                          <base-input class="col-9" label="Selecione a Montagem">
                            <select
                              v-model="servicoAdicional"
                              class="form-control"
                            >
                              <option
                                v-for="(tipo, index) in servicosAdicionais_"
                                v-bind:value="tipo.value"
                                :key="index"
                              >
                                {{ tipo.text }}
                              </option>
                            </select>
                          </base-input>

                          <div class="col-2">
                            <base-button
                              class="mt-4"
                              type="dark"
                              title="Adicionar"
                              @click="setServicoAdicionalToArray"
                              style="padding:10px; center "
                              fill
                              ><i class="fas fa-plus"></i
                            ></base-button>
                          </div>

                      </div>
                      <!-- <hr> -->
                      <!-- <div class="row">
                        <div class="col-10">
                        <base-input
                            class="col-12"
                            label="Valor de Montagem"
                            v-model="valor_montagem"
                            id="montagem_v"
                            type="number"
                          >
                          </base-input>
                      </div> -->

                      <!-- <div class="col-8">
                        <div
                          class="col-md-7 mt-2 "
                          style="background-color:#ff00000"
                        >
                          <base-input solo disabled>
                            Incluir {{servicoAdicional.text}}
                          </base-input>
                        </div>
                        <div class="col mr-lg-0">
                          <label class="switch">
                            <input
                              type="checkbox"
                              v-model="servicoAdicional.value"
                            />
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div> -->
                      </div>

                      <!-- <base-input class="col-10" label="Incluir Montagem e Desmontagem (25€/hora)" >
              <base-input type="checkbox" class="mt-1" v-model="servicoAdicional.value" ></base-input>
            </base-input> -->

                      <div>
                        <base-button
                          type="danger"
                          title="Anterior"
                          @click="goToLogisticArtigo"
                          style="padding:10px; center "
                          fill
                          ><i class="fas fa-arrow-left"></i
                        ></base-button>
                        <base-button
                          type="danger"
                          title="Próximo"
                          @click="goToRelatorio"
                          style="padding:10px; center "
                          fill
                          ><i class="fas fa-arrow-right"></i
                        ></base-button>
                      </div>

                      <div
                        v-if="hasDatas"
                        card
                        class="mt-5 "
                        style="background-color:#ffffff;"
                      >
                        <div class="table-responsive ">
                          <table class="table table-sm">
                            <tbody>
                              <tr
                                v-for="(artigo_, index) in artigos"
                                :key="index"
                              >
                                <!-- <th>
                                  <span style="color:#ff0000">{{
                                    index + 1
                                  }}</span>
                                </th> -->
                                <th>
                                    <i style="color:#ff0000" class="fas fa-minus"></i>
                                  </th>
                                <th scope="row">{{ artigo_ }}</th>
                                <th scope="row">
                                  <i
                                    @click="removeArtigoToArray(index)"
                                    class="fas fa-trash-alt"
                                  ></i>
                                </th>
                              </tr>

                              <!-- servicos adicionais -->
                                <tr
                                  v-for="(servicoAdicional, index) in servicosAdicionais"
                                  :key="index"
                                >
                                  <th>
                                    <i style="color:#ff0000" class="fas fa-minus"></i>
                                  </th>
                                  <th scope="row">{{ servicoAdicional }}</th>
                                  <th scope="row">
                                    <i
                                      @click="removeServicoAdicionalToArray(index)"
                                      class="fas fa-trash-alt"
                                      title="Apagar"
                                    ></i>
                                  </th>
                                </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="isRelatorioLogistica" class="col-md-12">
                    <div>
                      <h4 slot="header" style="color:#000000">
                        3. PRE VISUALIZAÇÃO DO SERVIÇO
                      </h4>
                      <div class="row mb-12">
                        <div class="col-10">
                          <!-- Tabela de previsualização  -->
                          <table class="table table-sm">
                            <tbody>
                              <tr>
                                <th scope="row">Local da Recolha:</th>
                                <td>{{ logisticaDetalhes.endereco_origem }}</td>
                              </tr>
                              <tr>
                                <th scope="row">Local de Entrega:</th>
                                <td>
                                  {{ logisticaDetalhes.endereco_destino }}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Destinatário:</th>
                                <td>
                                  {{ logisticaDetalhes.destinatario }} -
                                  {{ logisticaDetalhes.email }}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Data de envio:</th>
                                <td>{{ logisticaDetalhes.data_envio }}</td>
                              </tr>
                              <tr v-if="observacao != ''">
                                <th scope="row">Observações:</th>
                                <td>{{ logisticaDetalhes.observacao }}</td>
                              </tr>
                              <tr v-if="servicoAdicional.value">
                                <th scope="row">Serviço Adicional:</th>
                                <td>{{servicoAdicional.text}}</td>
                              </tr>
                              <tr>
                                <th scope="row">Todos os artigos:</th>
                                <td>{{ artigos }}</td>
                              </tr>
                              <tr v-if="servicosAdicionais.length>=1">
                                <th scope="row">Serviços de Montagem:</th>
                                <td>{{ servicosAdicionais }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="row">
                          <div class="col-md-3">
                            <base-input
                              label="Duração"
                              v-model="logisticaDetalhes.duracao"
                              placeholder="Tempo"
                              id="duracao"
                              disabled
                            >
                            </base-input>
                          </div>
                          <div class="col-md-3">
                            <base-input
                              label="Distância"
                              v-model="logisticaDetalhes.distancia"
                              placeholder="km"
                              id="distancia"
                              disabled
                            ></base-input>
                          </div>
                          <div class="col-md-3">
                            <base-input
                              label="Valor Inicial (€)"
                              v-model="logisticaDetalhes.valor_inicial"
                              placeholder="€"
                              id="custo_inicial"
                              disabled
                            ></base-input>
                          </div>
                        </div>
                        <div>
                          <base-button
                            type="danger"
                            @click="goToArtigoRelatorio"
                            title="Anterior"
                            style="padding:10px; center "
                            fill
                            ><i class="fas fa-arrow-left"></i
                          ></base-button>
                          <base-button
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            type="dark"
                            style="padding:10px; center "
                            fill
                            >Requisitar</base-button
                          >
                        </div>
                      </div>

                      <!-- Modal confirmacao-->
                      <div
                        class="modal fade"
                        id="exampleModal"
                        tabindex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="exampleModalLabel">
                                <strong>
                                  Tem a certeza que deseja registar o serviço?
                                </strong>
                              </h5>
                            </div>
                            <div class="modal-body">
                              <p>
                                Logistica de
                                <strong>{{
                                  logisticaDetalhes.destinatario
                                }}</strong
                                >, a ser entregue na
                                {{ logisticaDetalhes.endereco_destino }} aos
                                <strong>{{
                                  logisticaDetalhes.data_envio
                                }}</strong>
                                com um custo inicial de
                                <strong>
                                  {{ parseFloat(logisticaDetalhes.valor_inicial).toFixed(2) }}</strong
                                >
                              </p>
                            </div>
                            <div class="modal-footer">
                              <div class="row">
                                <button
                                  class="btn btn-danger "
                                  type="button"
                                  data-bs-dismiss="modal"
                                >
                                  <i class="fas fa-times"></i>
                                </button>
                                <button
                                  @click="setSalvar"
                                  class="btn btn-dark ml-2 ms-2"
                                >
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import NotificationTemplate from "./Notifications/NotificationTemplate";
// import Places from "vue-places";
import md5 from "js-md5";
import firebase from "../firebase";
import firebaseStorage from 'firebase';
import { IntegerPlusminus } from "vue-integer-plusminus";
var FCM = require("fcm-node");

export default {
  components: {
    // Places,
    IntegerPlusminus
  },

  computed: {
    hasDatas() {
      return this.artigos.length > 0;
    }
  },

  data() {
    return {
      reload_count: 0,
      file:null,

      checkedTrue: 0,
      qtd_artigo: null,
      minQtd: 1,
      countChackedServicoAdd: 0,
      db: firebase.ref("/logistica"),
      dbReferencias: firebase.ref("/referencias"),
      dbRastreio: firebase.ref("/rastreio"),
      dbDriver: firebase.ref("/motorista"),
      dbGeral:firebase,

      // servicoAdicional: {
      //   value: false,
      //   text: " Descarregar Camião (40€)",
      //   price: 40
      // },

      logisticaDetalhes: {
        confirmado_pagamento: "aguardando",
        numero_pedido: "",
        email: null,
        avaliacao: "aguardando",
        id_usuario: null,
        company_key: "aguardando",
        criado_em: null,
        custo: "",
        custo_da_distancia: null,
        data_envio: null,
        destinatario: null,
        distancia: null,
        duracao: null,
        endereco_destino: null,
        endereco_origem: null,
        fim: "aguardando",
        id_motorista: "aguardando",
        id_usuario: null,
        inicio: "aguardando",
        key: "",
        latitude_destino: null,
        latitude_origem: null,
        longitude_destino: null,
        longitude_origem: null,
        nome_motorista: "aguardando",
        nome_usuario: null,
        observacao: "",
        pagamento: "aguardando",
        referencia: null,
        regiao: null,
        status: "aguardando",
        taxa_cobranca: "15",
        taxa_envio: "0",
        telefone_usuario: null,
        tipo: "Produto Individual",
        tipo_casa: "",
        tipo_veiculo: "",
        tipo_usuario: "ik_business",
        token_motorista: "aguardando",
        valor_inicial: null,
        periodo_entrega: "",
        tipo_entrega: "",
        nota_fiscal:"",
        artigos:null,
        servico_adicional:null
      },
      valor_montagem:0,

      peridos: [
        { text: "Selecione o Período:", value: "" },
        { text: "Manhã", value: "Manhã" },
        { text: "Tarde", value: "Tarde" },
        { text: "Noite", value: "Noite" }
      ],

      // montagem_selecionada:"€ 0",
      // preco_montagens: [
      //   { text: "Selecione a Montagem:", value: "€ 0" },
      // ],

      //refere-se a montagens
      servicoAdicional:"€ 0",
      servicosAdicionais_: [
        { text: "Selecione:", value: "€ 0" },
      ],
      servicosAdicionais:[],

      entregas: [
        { text: "Selecione o Tempo de Entrega:", value: "" , price:0},
      ],

      custo_unico: "70",

      isArtigo: false,
      isDetailLogistica: true,
      isRelatorioLogistica: false,

      regiaoOrigin: null,

      isErro: false,
      artigo: "",
      artigos: [],
      drivers: [],
      artigos_stock:[{text: "Selecione o Artigo:", value: "" }],
    };
  },

  mounted() {
    setTimeout(() => {  console.log("World!"); }, 5000);

    if (localStorage.getItem('reloaded')) {
        localStorage.removeItem('reloaded');
    } else {
        localStorage.setItem('reloaded', '1');
        location.reload();
    }

    this.getLogistica()

    // origen
    var autocompleteOrigin = new google.maps.places.Autocomplete(
      document.getElementById("origin"),
      {
        bounds: new google.maps.LatLngBounds(
          new google.maps.LatLng(39.399872, -8.224454)
        )
      }
    );

        //  destin
    new google.maps.places.Autocomplete(document.getElementById("destin"), {
      bounds: new google.maps.LatLngBounds(
        new google.maps.LatLng(39.399872, -8.224454)
      )
    });

    new google.maps.event.addListener(
      autocompleteOrigin,
      "place_changed",
      function() {
        this.regiaoOrigin =  autocompleteOrigin.getPlace().address_components[1].long_name;
        // alert(this.regiaoOrigin)
        document.getElementById(
          "regiao"
        ).value = autocompleteOrigin.getPlace().address_components[1].long_name;
      }
    );

    this.getPrecoMontagens();
    this.getPrecoEntregas();
    this.getArtigosStock()

  },

  methods: {
    getLogistica(){
     // console.log('clicado')
      this.db.child(this.$route.params.logisticaEditKey).on('value',(snap)=>{
        let data = snap.val();

        this.artigos = data.artigos
        if(data.servico_adicional!=null)
          this.servicosAdicionais = data.servico_adicional

        this.logisticaDetalhes.referencia = data.referencia
        this.logisticaDetalhes.numero_pedido = data.numero_pedido
        this.logisticaDetalhes.email = data.email
        this.logisticaDetalhes.nome_usuario = data.nome_usuario
        this.logisticaDetalhes.endereco_origem = data.endereco_origem
        this.logisticaDetalhes.endereco_destino = data.endereco_destino
        document.getElementById("origin").value = data.endereco_origem
        document.getElementById("destin").value = data.endereco_destino
        this.logisticaDetalhes.data_envio = data.data_envio
        this.logisticaDetalhes.tipo_entrega = data.tipo_entrega
        this.logisticaDetalhes.periodo_entrega = data.periodo_entrega
        this.logisticaDetalhes.observacao = data.observacao
        this.logisticaDetalhes.destinatario = data.destinatario
        this.logisticaDetalhes.status = data.status
        this.logisticaDetalhes.id_usuario = data.id_usuario
        this.logisticaDetalhes.token_motorista = data.token_motorista
        this.logisticaDetalhes.nome_motorista = data.nome_motorista
        this.logisticaDetalhes.regiao = data.regiao
        this.logisticaDetalhes.company_key = data.company_key
        this.logisticaDetalhes.confirmado_pagamento = data.confirmado_pagamento
        this.logisticaDetalhes.nota_fiscal = data.nota_fiscal
        this.logisticaDetalhes.key = data.key
      });
    },

    refresh() {
      window.location.reload();
    },
    click1() {
      this.$refs.input1.click()
    },
    getFile(event) {
      this.uploadValue=0;
      this.logisticaDetalhes.nota_fiscal=null;
      this.file = event.target.files[0];
      this.onUpload()
    },

    getArtigosStock() {
      this.dbGeral.ref("/stock/produtos").on("value", (snap) => {
        snap.forEach((item) => {
          let key = item.key;
          let data_ = item.val();
          console.log(data_)
          this.artigos_stock.push(
            { text: data_.cadastro.nome  ,value: data_.cadastro.nome },
          );

        });
      });
    },


    onUpload(){
      const storageRef=firebaseStorage.storage().ref("nota_fiscal/"+`${this.file.name}`).put(this.file);
      storageRef.on(`state_changed`,snapshot=>{
      this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
        }, error=>{console.log(error.message)},
      ()=>{this.uploadValue=100;
          storageRef.snapshot.ref.getDownloadURL().then((url)=>{
              this.logisticaDetalhes.nota_fiscal =url;
              console.log(this.logisticaDetalhes.nota_fiscal)
            });
          }
        );
    },
      // Notification FCM
    sentNotification () {


    var serverKey = 'AAAAZZEIerk:APA91bE5y4deGEp3bwB5R7r7EoZcJ3U-rcDWTtb-mX-3XEE-04F2OmfowhnFXOsaTodvSOJREKp6GqfKxWmQtqea1C_jlAJ1g7Nmo4GiDwretXjCHtkVFF3WJjoz1RmM3ODq1Fu_5ZH9'; //put your server key here
    var fcm = new FCM(serverKey);


         this.drivers.forEach(deviceToken => {
           if(deviceToken != null){
                console.log(deviceToken);

                  var message = {
                      to: deviceToken,
                      collapse_key: 'your_collapse_key',

                      notification: {
                          title: "Caro motorista",
                          body: "Há novas entregas na empresa!"
                      },

                      data: {
                          my_key: 'my value',
                          my_another_key: 'my another value'
                      }
                  };

                  fcm.send(message, function(err, response){
                      if (err) {
                          console.log("Erro ao enviar a NOTIFICAÇÃO!");
                      } else {
                          console.log("Notificação enviada com sucesso: ", response);
                      }
                  });


           }
         });



      },


    goToRelatorio() {
      if (this.artigos.length < 1) {
        this.isErro = true;
      } else {
        this.isArtigo = false;
        this.isDetailLogistica = false;
        this.isRelatorioLogistica = true;

        var custoAdiconal = 0

        let vetor = this.servicosAdicionais
        for (let i = 0; i < vetor.length; i++) {
          custoAdiconal = custoAdiconal + parseFloat(vetor[i].substring(vetor[i].indexOf('€ ') + 1))
        }

        // Preenchimento do valor inicial com base no Tipo de entrega
        var str1 = this.logisticaDetalhes.tipo_entrega
        // var str2 = this.montagem_selecionada
        this.logisticaDetalhes.valor_inicial = parseFloat(custoAdiconal) + parseFloat(str1.substring(str1.indexOf('€ ') + 1))
        //  this.logisticaDetalhes.valor_inicial = parseFloat(str2.substring(str2.indexOf('€ ') + 1)) + parseFloat(str1.substring(str1.indexOf('€ ') + 1))


        //controle do chacbox de servicos adicionais
        // if(this.getValorServicosAdicinais(this.servicoAdicional.value)!=0)
        if (this.countChackedServicoAdd < 2)
          this.logisticaDetalhes.valor_inicial =
            this.logisticaDetalhes.valor_inicial +
            this.getValorServicosAdicinais(this.servicoAdicional.value);

        if (
          this.countChackedServicoAdd == 2 &&
          this.servicoAdicional.value == false
        )
          this.logisticaDetalhes.valor_inicial =
            this.logisticaDetalhes.valor_inicial - this.servicoAdicional.price;

      }
    },

    goToArtigoRelatorio() {
      this.isArtigo = true;
      this.isDetailLogistica = false;
      this.isRelatorioLogistica = false;
    },

    goToLogisticArtigo() {
      this.isArtigo = false;
      this.isDetailLogistica = true;
      this.isRelatorioLogistica = false;
    },

     getPrecoMontagens() {
      this.dbGeral.ref("/montagem_b2b").once("value", (snap) => {
        snap.forEach((item) => {
          // let key = item.key;
          let data_ = item.val();
          this.servicosAdicionais_.push(
            { text: data_.nome +' - ' + '€ '+ data_.preco, value: data_.nome +' - ' + '€ '+ data_.preco}
          );

        });
      });
    },

    // getPrecoMontagens() {
    //   this.dbGeral.ref("/montagem_b2b").on("value", (snap) => {
    //     snap.forEach((item) => {
    //       let key = item.key;
    //       let data_ = item.val();
    //       // console.log(data_)
    //       this.preco_montagens.push(
    //         { text: data_.nome +' - ' + '€ '+ data_.preco, value: data_.nome +' - ' + '€ '+ data_.preco}
    //       );

    //     });
    //   });
    // },

     getPrecoEntregas() {
      this.dbGeral.ref("/tipo_entrega").on("value", (snap) => {
        snap.forEach((item) => {
          let key = item.key;
          let data_ = item.val();
          console.log(data_)
          if(data_.regiao === this.$session.get("regiao") || data_.regiao === "Portugal"){
            this.entregas.push(
              { text: data_.nome +' - ' + '€ '+ data_.preco,  value: data_.nome +' - ' + '€ '+ data_.preco,  price: data_.preco },
            );
          }

        });
      });
    },

    getArtigo() {
      if (
        document.getElementById("origin").value == "" &&
        document.getElementById("destin").value == "" &&
        this.logisticaDetalhes.duracao == null &&
          this.logisticaDetalhes.distancia == null
      ) {
        this.goToArtigoRelatorio;
        this.$swal("Erro!", "Os endereços estão vazios.", "");
      } else if (this.logisticaDetalhes.periodo_entrega === "") {
        this.goToArtigoRelatorio;
        this.$swal("Erro!", "O Período de entrega não foi selecionado.", "");
      } else if (this.logisticaDetalhes.data_envio == null) {
        this.goToArtigoRelatorio;
        this.$swal("Erro!", "A Data de envio não foi selecionada.", "");
      } else if (this.logisticaDetalhes.tipo_entrega =="") {
        this.goToArtigoRelatorio;
        this.$swal("Erro!", "O tipo de entrega não foi selecionado.", "");
      } else {
        this.getDistance(
          document.getElementById("origin").value,
          document.getElementById("destin").value
        );
        this.isArtigo = true;
        this.isDetailLogistica = false;
        this.isRelatorioLogistica = false;

        var date_format = new Date(this.logisticaDetalhes.data_envio); //format
        const data_envio =
          date_format.getFullYear() +
          "-" +
          ("0" + (date_format.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + date_format.getDate()).slice(-2);

        // const data_envio = (document.getElementById("id_data_de_envio").value).toISOString().slice(0, 19).replace(/-/g, "/").replace("T", " ");

        // this.logisticaDetalhes.criado_em = this.currentDateTime();
        // (this.logisticaDetalhes.id_usuario = this.$session.get("key")),
        //   (this.logisticaDetalhes.nome_usuario = this.$session.get("nome")),
        //   (this.logisticaDetalhes.telefone_usuario = this.$session.get(
        //     "phone"
        //   )),
        //   (this.logisticaDetalhes.regiao = document.getElementById(
        //     "regiao"
        //   ).value);
        this.logisticaDetalhes.data_envio = data_envio;




        //Retornar todos os tokens dos Drivers para o envio de notificacaoes
        this.getDrivers();
      }hoje
    },

    getDistance(origin, destin) {
      const directionsService = new google.maps.DirectionsService();
      directionsService.route(
        {
          origin: origin,
          destination: destin,
          travelMode: "DRIVING"
        },
        (response, status) => {
          console.log(response.routes[0].legs[0].distance.text);
          console.log(response.routes[0].legs[0].duration.text);

          this.logisticaDetalhes.duracao =
            response.routes[0].legs[0].duration.text;
          this.logisticaDetalhes.distancia =
            response.routes[0].legs[0].distance.text;
          this.logisticaDetalhes.endereco_origem =
            response.routes[0].legs[0].start_address;
          this.logisticaDetalhes.endereco_destino =
            response.routes[0].legs[0].end_address;
          this.logisticaDetalhes.latitude_origem = String(
            response.routes[0].legs[0].start_location.lat()
          );
          this.logisticaDetalhes.longitude_origem = String(
            response.routes[0].legs[0].start_location.lng()
          );
          this.logisticaDetalhes.latitude_destino = String(
            response.routes[0].legs[0].end_location.lat()
          );
          this.logisticaDetalhes.longitude_destino = String(
            response.routes[0].legs[0].end_location.lng()
          );
          // this.logisticaDetalhes.custo_da_distancia = String(
          //   this.getValorKmAdicional(response.routes[0].legs[0].distance.value)
          // );
          this.logisticaDetalhes.custo = 0;
          3

          // if(this.logisticaDetalhes.tipo_veiculo == 'Classe 3'){
          //   this.logisticaDetalhes.custo = "    " + this.carros[2].custo
          //   this.logisticaDetalhes.valor_inicial = parseInt(this.carros[2].custo) + this.getValorKmAdicional(response.routes[0].legs[0].distance.value, this.logisticaDetalhes.tipo_veiculo)
          // }
        }
      );
    },

    // custo da kilometragem adicional
    getValorKmAdicional(kilometragem) {
      var total = parseInt(kilometragem * 0.001);
      var totalKmAdicional = 0;
      var custoFinal = 0;

      if (total > 20) {
        totalKmAdicional = total - 20;
        if (totalKmAdicional > 0) {
          custoFinal = totalKmAdicional;
        }
      }

      return custoFinal;
    },

    // Custo dos servicos adicionais - verifica se o checkbox (se servico adicional) é verdadeiro ou Falso
    getValorServicosAdicinais(isvalue) {
      var valor = 0;
      if (isvalue == true) {
        valor = this.servicoAdicional.price;
        this.countChackedServicoAdd++;
      }
      return valor;
    },

    getDrivers() {
      var drivers_ = [];

      this.dbDriver.on("value", snap => {
        // let data = snap.val();
        snap.forEach(item => {
          //let key = item.key;
          let data = item.val();
          drivers_.push(data.perfil.device_token);
        });

        this.drivers = drivers_;

        console.log(this.drivers);
      });
    },

    //Enviar dados para a base de dados
    setSalvar() {
      this.logisticaDetalhes.artigos = this.artigos
      this.logisticaDetalhes.servico_adicional=this.servicosAdicionais

      this.logisticaDetalhes.custo =  String(this.logisticaDetalhes.valor_inicial)
      this.logisticaDetalhes.valor_inicial =
        String(
          this.logisticaDetalhes.valor_inicial
        ) + "€";
      this.db.child(this.$route.params.logisticaEditKey)
        .update(this.logisticaDetalhes)
        .then(result => {
          //adição de servicos adiconais à logistica
            this.db.child(this.$route.params.logisticaEditKey).child("servico_adicional").set(this.servicosAdicionais);



          //  addicao de artigos
          this.db.child(this.$route.params.logisticaEditKey).child("artigos").set(this.artigos);
          //referencia



          // Sweet Alert de Sucesso!
          this.$swal({
            title: 'Sucesso',
            text: "Atualizado com sucesso!",
            icon: 'success',
            type: "success",
            showConfirmButton: true,
            confirmButtonColor: '#3085d6',
          }).then((result) => {
            if (result.isConfirmed) {
              console.log("Refrescando....");
              this.refresh();
            }
          })


        })
        .catch(error => {
          this.$swal("Erro", "Tente novamente," + error, "error");
        });
    },

    setArtigoToArray() {
      if (this.artigo == "" || this.qtd_artigo == null) {
        this.isErro = true;
      } else {
        this.artigos.push(this.qtd_artigo + " " + this.artigo);
        this.artigo = "";
        this.isErro = false;
      }
    },

    setServicoAdicionalToArray() {
      if(this.artigos.length<1){
        this.$swal(
            'Erro',
            'Primeiro adicione artigos!',
            '')
      }else{
        if (this.servicoAdicional=="€ 0") {
          this.$swal(
            'Erro',
            'Selecione a montagem!',
            '')
        }  else {
          this.servicosAdicionais.push(this.servicoAdicional);
          this.servicoAdicional="€ 0"
        }
      }
    },
    removeServicoAdicionalToArray(index) {
      this.servicosAdicionais.splice(index, 1);
    },

    getReferencia(pwd) {
      return ("LG" + md5(pwd).substring(1, 10)).toUpperCase();
    },

    currentDateTime() {
      const current = new Date();
      const date =
        current.getFullYear() +
        "-" +
        (current.getMonth() + 1) +
        "-" +
        current.getDate();
      const time =
        current.getHours() +
        ":" +
        current.getMinutes() +
        ":" +
        current.getSeconds();
      const dateTime = date + " " + time;

      return dateTime;
    },

    removeArtigoToArray(index) {
      this.artigos.splice(index, 1);
    },

    notifyVue(verticalAlign, horizontalAlign) {
      const color = Math.floor(Math.random() * 4 + 1);
      this.$notify({
        component: NotificationTemplate,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: this.type[color],
        timeout: 0
      });
    }
  }
};
</script>

<style>
/* Datetime */
.theme-orange .vdatetime-popup__header,
.theme-orange .vdatetime-calendar__month__day--selected > span > span,
.theme-orange .vdatetime-calendar__month__day--selected:hover > span > span {
  background: #ed0455;
}

.theme-orange .vdatetime-year-picker__item--selected,
.theme-orange .vdatetime-time-picker__item--selected,
.theme-orange .vdatetime-popup__actions__button {
  color: #ed0455;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
