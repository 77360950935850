
<template>
  <div class="home card-local">
    <div id="map" title="Google Maps">
      <div id="map" ref="map">
      </div>
    </div>
  </div>
</template>
<script>
import { API_KEY } from "../services/API_KEY";
import {Loader} from 'google-maps';
const loader = new Loader(API_KEY);
import firebase from "firebase";
import firebase_ from "../firebase";
export default {
  name: 'Home',
  components: {
  },
  data(){
    return{
      user: null,
      lat: 0,
      lng: 0,
      watchPositionId: null ,
      map: null,
      driversKey:[],

      db: firebase_,
      track:[]
    }
  },
  created() {
    loader.load().then(function (google) {

      var mapOptions = {
        zoom: 10,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        center: new google.maps.LatLng(39.8116275,-7.5331471),
        scrollwheel: false, // we disable de scroll over the map, it is a really annoing when you scroll through page

      };
      new google.maps.Map(document.getElementById("map"), mapOptions);
    })
  },

  async mounted() {

    loader.load().then(function (google) {
      navigator.geolocation.watchPosition(
        position => {

          var mapOptions = {
            zoom: 13,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            center: new google.maps.LatLng(position.coords.latitude, position.coords.longitude),
            scrollwheel: false, // we disable de scroll over the map, it is a really annoing when you scroll through page

          };
          var map =new google.maps.Map(document.getElementById("map"), mapOptions);

          new google.maps.Marker({
            position: {lat: position.coords.latitude, lng: position.coords.longitude},
            map: map,
            title:  "Sua atual localização.",
            // icon:"https://firebasestorage.googleapis.com/v0/b/iklogo.appspot.com/o/pngtree-people-location-pin-icon-png-image_1728979.jpg?alt=media&token=1590a11f-0363-43bd-8eee-686c434846cb",
          });


          firebase.database().ref("/logistica").once("value", (snap) => {
            snap.forEach((item) => {
              // let key = item.key;
              let data_ = item.val();
              if(data_.id_usuario===this.$session.get('key')){
                if(data_.status==='pendente'){

                  firebase.database().ref("/rastreio").once("value", (snap) => {
                    snap.forEach((item) => {
                      let key = item.key;
                      let data = item.val();
                      if(key===data_.id_motorista && data_.data_envio.substring(0,10) === data.date.substring(0,10)){

                        new google.maps.Marker({
                          position: {lat: data.latitude, lng: data.longitude},
                          map: map,
                          title:  "Localização da Encomenda ("+data_.referencia+").",
                          icon:"https://firebasestorage.googleapis.com/v0/b/iklogo.appspot.com/o/car_classes%2Fimg2.png?alt=media&token=206222a6-7ae1-4078-8eda-f95f925c6c8c",
                        });
                      }

                    });
                  });
                }
              }

            });
          });


        },
        error => {
          console.log(error.message);
        }
      );

    })
  },


  methods:{}

}
</script>
<style>
#map{
  height:1000px;
  background: gray;
}

</style>
