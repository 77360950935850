<template>
  <div class="main-panel register-bg" style="margin: auto">
    <div class="content" style="margin: auto">
      <fade-transition :duration="100" mode="out-in">
        <div class="cartao" style="margin-top: 40px;">
          <!-- <card type="user" class="cartao" style="margin: auto"> -->
            <p class="card-text"></p>
            <div class="author">
              <div class="block block-one"></div>
              <div class="block block-two"></div>
              <div class="block block-three"></div>
              <div class="block block-four"></div>
              <a href="#">
                <!-- <img class="avatar" src="img/perfil_icon.png" alt="..."> -->
                <!-- <img class="avatar" src="https://img.icons8.com/fluency/96/000000/user-male-circle.png"/> -->
                <!-- <h5 class="title">NOME</h5> -->
              </a>

              <!-- BOx dos campos -->
              <div class="row">
                <div class="col-md-4"></div>
                <div class="col-md-4">
                  <div class="card" style="margin: auto">
                    <div class="">
                      <h3 class="text-info"><strong>REGISTO</strong></h3>
                      <h6 class="font-weight-light text-primary">
                        Área de registo
                      </h6>

                      <div class="form-group">
                        <input
                          type="email"
                          v-model="company.email"
                          class="form-control form-control-lg"
                          id="exampleInputEmail1"
                          placeholder="Email"
                          required
                        />
                      </div>
                      <div class="form-group">
                        <input
                          type="text"
                          v-model="company.endereco"
                          class="form-control form-control-lg"
                          id="endereco"
                          placeholder="Endereço"
                        />
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <input
                              type="text"
                              v-model="company.nome"
                              class="form-control form-control-lg"
                              id="exampleInputUsername1"
                              placeholder="Nome"
                              required
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <input
                              type="password"
                              v-model="company.senha"
                              class="form-control form-control-lg"
                              id="exampleInputPassword1"
                              placeholder="Password"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <input id="lat" type="hidden" />
                      <input id="lng" type="hidden" />

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <input
                              type="text"
                              v-model="company.phone"
                              class="form-control form-control-lg"
                              id="contato"
                              placeholder="Contato"
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <select
                              v-model="selected"
                              class="form-control"
                              id="inputGroupSelect04"
                            >
                              <option
                                class="text-info"
                                v-for="(option, index) in options"
                                v-bind:value="option.value"
                                :key="index"
                              >
                                {{ option.text }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-8">
                          <div class="mb-4">
                            <div class="form-group">
                              <label class="form-control">
                                <input
                                  type="checkbox"
                                  v-model="isChecked"
                                  class="form-check-input"
                                />
                                <span class="text-info">
                                  &nbsp; Concordo com os termos e
                                  condições</span
                                >
                                <i class="input-helper"></i>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="mb-2">
                            <button
                              @click="saveCampany"
                              class="
                                btn btn-block btn-danger
                                small
                                btn-lg
                                font-weight-medium
                              "
                              fill
                            >
                              Salvar
                            </button>
                          </div>
                        </div>
                      </div>

                      <div class="text-center mt-4 font-weight-light">
                        Possue uma conta?
                        <router-link to="/auth/login" class="text-danger"
                          >Login</router-link
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4"></div>
              </div>
            </div>
            <p></p>

            <!-- <div slot="footer" class="button-container">
      <base-button icon round class="btn-facebook">
          <a target="_blank" href="https://web.facebook.com/iK-Tech-Solutions-108737344645221"  class="text-white">
        <i class="fab fa-facebook"></i>
          </a>
      </base-button>
      <base-button icon round class="btn-instagram">
       <a target="_blank" href="https://www.instagram.com/ik_techsolutions/?fbclid=IwAR3fui8NZ8JJBOUWrG5HFo6XkmoO6krWmcUtXtILMQRx67Yek1Dh_Cb5UEg"  class="text-white">
        <i class="fab fa-instagram"></i>
       </a>
      </base-button>
    </div> -->
          <!-- </card> -->
        </div>
      </fade-transition>
    </div>
  </div>
</template>

<script>
// import UserService from "../../services/UserService";
import NotificationTemplate from "../Notifications/NotificationTemplate";
import { BaseAlert } from "@/components";
import firebase from "../../firebase";

import { API_KEY } from "../../services/API_KEY";
import { Loader, LoaderOptions } from "google-maps";
const loader = new Loader(API_KEY);
export default {
  name: "register",
  components: {
    BaseAlert,
  },
  data() {
    return {
      db: firebase.ref("/cliente"),
      selected: "Porto",
      options: [
        { text: "Porto", value: "Porto" },
        { text: "Lisboa", value: "Lisboa" },
        { text: "Braga", value: "Braga" },
        { text: "Faro", value: "Faro" },
      ],
      type: ["", "info", "success", "warning", "danger"],
      notifications: {
        topCenter: false,
      },
      company: {
        device_token: "",
        type_user: "ik_business",
        nome: null,
        endereco: null,
        regiao: null,
        phone: null,
        uid: null,
        email: null,
        senha: null,
        status: "aguardando",
      },
      coordenadas: {
        lat: "",
        lng: "",
      },
      isChecked: false,
    };
  },

  mounted() {
    loader.load().then(function (google) {
      // Metodo de Geolocalização
      navigator.geolocation.getCurrentPosition((position) => {
        // console.log(position.coords.latitude)
        document.getElementById("lat").value = position.coords.latitude;
        document.getElementById("lng").value = position.coords.longitude;
      });
    });
  },

  methods: {
    getCoordenadas() {
      this.coordenadas.lat = document.getElementById("lat").value;
      this.coordenadas.lng = document.getElementById("lng").value;
    },

    notifyVue(verticalAlign, horizontalAlign) {
      const color = Math.floor(Math.random() * 4 + 1);
      this.$notify({
        component: NotificationTemplate,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: this.type[color],
        timeout: 0,
      });
    },

    saveCampany() {
      this.getCoordenadas();
      if (
        this.company.nome != null &&
        this.company.email != null &&
        this.company.senha != null &&
        this.isChecked != false
      ) {
        this.company.regiao = this.selected;
        var dbSeve = this.db.push();
        this.company.uid = dbSeve.key;
        dbSeve
          .child("perfil")
          .set(this.company)
          .then(() => {
            dbSeve.child("track").set(this.coordenadas); //coordenadas da atual localizaçao
            this.$router.push("/auth/login");
            this.$swal(
              "Sucesso!",
              "A sua subscrição foi efetuada com sucesso. Aguarde pela ativação da conta!",
              "success"
            );
          })
          .catch((e) => {
            this.$swal("Falha!", "Ocorreu erro ao tentar cadastrar.", "error");
          });
      } else {
        this.$swal("Falha!", "Campos vazios não são aceites.", "error");
      }
    },
  },
};
</script>


<style>
.register-bg{
  /* background-image: url(../images/lockscreen-bg.jpeg); */
  background: url(../../assets/images/lockscreen-bg.jpeg) no-repeat center center fixed;
  position: fixed;
  top: 0;
  left: 0;

  /* Preserve aspet ratio */
  min-width: 100%;
  min-height: 100%;
}
.cartao {
  border: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
}
.chat {
  margin-top: auto;
  margin-bottom: auto;
}
.card {
  height: 100%;
  width: 100%;
  padding: 14px;
  border-radius: 15px !important;
  border-bottom-color: #d48919;
  background-color: rgb(255, 255, 255) !important;
}

.cardd {
  height: 50%;
  width: 100%;
  padding: 14px;
  border-radius: 15px !important;
  border-bottom-color: #000000;
  background-color: rgb(255, 255, 255) !important;
}
.contacts_body {
  padding: 0.75rem 0 !important;
  overflow-y: auto;
  white-space: nowrap;
}
.msg_card_body {
  overflow-y: auto;
}
.card-header {
  border-radius: 15px 15px 0 0 !important;
  border-bottom: 0 !important;
}
.card-footer {
  border-radius: 0 0 15px 15px !important;
  border-top: 0 !important;
}
.container {
  align-content: center;
}
.search {
  border-radius: 15px 0 0 15px !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
}
.search:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.type_msg {
  background-color: rgba(253, 253, 253, 0.3) !important;
  border: 0 !important;
  color: rgb(0, 0, 0) !important;
  font-size: 20px;
  font-weight: bold;
  height: 60px !important;
  overflow-y: auto;
}
.type_msg:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.attach_btn {
  border-radius: 15px 0 0 15px !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
  cursor: pointer;
}
.send_btn {
  border-radius: 0 15px 15px 0 !important;
  background-color: rgb(255, 255, 255) !important;
  border: 0 !important;
  border-color: #ffffff;
  color: rgb(0, 0, 0) !important;
  cursor: pointer;
}
.search_btn {
  border-radius: 0 15px 15px 0 !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
  cursor: pointer;
}
.contacts {
  list-style: none;
  padding: 0;
}
.contacts li {
  width: 100% !important;
  padding: 5px 10px;
  margin-bottom: 15px !important;
}
.active {
  background-color: rgba(0, 0, 0, 0.3);
}
.user_img {
  height: 70px;
  width: 70px;
  border: 1.5px solid #f5f6fa;
}
.user_img_msg {
  height: 40px;
  width: 40px;
  border: 1.5px solid #f5f6fa;
}
.img_cont {
  position: relative;
  height: 70px;
  width: 70px;
}
.img_cont_msg {
  height: 40px;
  width: 40px;
}
.online_icon {
  position: absolute;
  height: 15px;
  width: 15px;
  background-color: #4cd137;
  border-radius: 50%;
  bottom: 0.2em;
  right: 0.4em;
  border: 1.5px solid white;
}
.offline {
  background-color: #c23616 !important;
}
.user_info {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
}
.user_info span {
  font-size: 20px;
  color: white;
}
.user_info p {
  font-size: 10px;
  color: rgba(255, 255, 255, 0.6);
}
.video_cam {
  margin-left: 50px;
  margin-top: 5px;
}
.video_cam span {
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin-right: 20px;
}
.msg_cotainer {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  border-radius: 25px;
  background-color: #82ccdd;
  padding: 10px;
  padding-right: 70px;
  position: relative;
}
.msg_cotainer_send {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
  border-radius: 25px;
  background-color: #78e08f;
  padding: 10px;
  padding-left: 70px;
  position: relative;
}
.msg_time {
  position: absolute;
  left: 0;
  bottom: -15px;
  color: rgb(0, 0, 0);
  font-size: 12px;
}
.msg_time_send {
  padding-top: 15px;
  position: absolute;
  right: 0;
  bottom: -15px;
  color: rgb(0, 0, 0);
  font-size: 12px;
}
.msg_head {
  position: relative;
}
#action_menu_btn {
  position: absolute;
  right: 10px;
  top: 10px;
  color: white;
  cursor: pointer;
  font-size: 20px;
}
.action_menu {
  z-index: 1;
  position: absolute;
  padding: 15px 0;
  background-color: rgb(119, 118, 105);
  color: white;
  border-radius: 15px;
  top: 30px;
  right: 15px;
  display: none;
}
.action_menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.action_menu ul li {
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 5px;
}
.action_menu ul li i {
  padding-right: 10px;
}
.action_menu ul li:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.973);
}
@media (max-width: 576px) {
  .contacts_card {
    margin-bottom: 15px !important;
  }
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #7f7fd5;
  border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5454b6;
}
</style>
