<template>


<div class="">

    <div class="card-body all-icons">
      <div class="" style="height: 100%">
    <div class="row justify-content-center ">

      <div class="col-md-12 col-xl-12 ">
        <div class="card ">
          <div class="card-header msg_head" style="color:#000000; background-color:#ee0556;">
            <div class="d-flex bd-highlight">

              <div class="user_info">
                <span>Editar Perfil</span>
                <p></p>
              </div>

            </div>
          </div>
          <div class="card-body msg_card_body">

<!-- Body -->
  <div>
    <div class="row">
      <div class="col-md-6">
        <base-input label="Email"
                  placeholder="Email"
                  v-model="user.email"
                  disabled>
        </base-input>
      </div>
      <div class="col-md-6">
        <base-input label="Nome"
                  placeholder="Nome"
                  v-model="user.nome">
        </base-input>
      </div>

    </div>


    <div class="row">
            <div class="col-md-6">
        <base-input label="Novo Password"
                  type="password"
                   v-model="user.senha"
                  placeholder="**********">
        </base-input>
      </div>
        <div class="col-md-6 ">
           <base-input label="Endereço"
                  v-model="user.endereco"
                  placeholder="Endereço do estabelecimento">
        </base-input>
      </div>

    </div>

    <div class="row">
             <div class="col-md-6">
        <base-input label="Contacto"
                  v-model="user.phone"
                  placeholder="Contacto">
        </base-input>
      </div>

      <div class="col-md-6">
        <base-input label="Região"
                  v-model="user.regiao"
                  placeholder="Região" disabled>
        </base-input>
      </div>
    </div>

    <base-button slot="footer" @click="updateUser" type="danger" fill>Salvar</base-button>
  </div>


          </div>

        </div>
      </div>
    </div>
  </div>
    </div>
  </div>



</template>
<script>
  import firebase from "../../firebase";

  export default {
    props: {
      model: {
        type: Object,
        default: () => {
          return {};
        }
      }
    },

    data() {
      return {
        db:firebase.ref("/cliente"),
        user:{
          email:null,
          endereco:null,
          nome:null,
          senha:null,
          phone:null,
          regiao:null

        }
      }
    },
    methods:{

      getUser(){
        this.db.child(this.$session.get('key')).child('perfil').on('value',(snap)=>{
          let data = snap.val();

          this.user.email = data.email,
          this.user.endereco = data.endereco,
          this.user.nome = data.nome,
          this.user.senha = data.senha,
          this.user.phone = data.phone,
          this.user.regiao = data.regiao
        });
      },

      updateUser(){
        this.db.child(this.$session.get('key')).child('perfil').update(this.user)
        .then((result)=>{
          this.$swal(
            'Sucesso',
            'A sua conta foi atualizada com sucesso!',
            'success' )

          this.getUser()
        })
        .catch((error)=>{
          this.$swal(
            'Erro',
            'Tente novamente,'+error,
            'error')
        })
      },

    },

    mounted(){
      this.getUser()
    }
  }
</script>
<style>
</style>

    <style>



</style>


