<template>
  <div class="main-panel login-bg" style="margin: auto">
    <div class="content" style="margin-top: 30px;">
      <fade-transition :duration="100" mode="out-in">
        <div class="cartao" style="margin-top: 40px;">
          <!-- <card type="user" class="cartao" style="margin: auto"> -->
            <p class="card-text"></p>
            <div class="author">
              <div class="block block-one"></div>
              <div class="block block-two"></div>
              <div class="block block-three"></div>
              <div class="block block-four"></div>
              <a href="#">
                <!-- <img class="avatar" src="img/perfil_icon.png" alt="..."> -->
                <!-- <img class="avatar" src="https://img.icons8.com/fluency/96/000000/user-male-circle.png"/> -->
                <!-- <h5 class="title">NOME</h5> -->
              </a>

              <!-- BOx dos campos -->
              <div class="row">
                <div class="col-md-4"></div>
                <div class="col-md-4">
                  <div class="card" style="margin: auto">
                    <div class="">
                      <h3 class="text-info"><strong>LOGIN</strong></h3>
                      <h6 class="font-weight-light text-primary">
                        Área do IK BUSINESS
                      </h6>
                      <div class="form-group">
                        <base-input
                          type="email"
                          v-model="user.username"
                          class="form-control form-control-lg"
                          id="exampleInputEmail1"
                          placeholder="Email"
                        >
                        </base-input>
                      </div>
                      <div class="form-group">
                        <base-input
                          type="password"
                          v-model="user.password"
                          class="form-control form-control-lg"
                          id="exampleInputPassword1"
                          placeholder="Password"
                        >
                        </base-input>
                      </div>
                      <div class="mt-3 align-center">
                        <button
                          @click="login"
                          class="
                            btn btn-block btn-danger
                            col-md-4
                            font-weight-medium
                            auth-form-btn
                          "
                        >
                          ENTRAR
                        </button>
                      </div>
                      <div
                        class="
                          my-2
                          d-flex
                          justify-content-between
                          align-items-center
                        "
                      >
                        <div class="form-check">
                          <!-- <label class="form-check-label text-muted">
                        <input type="checkbox" class="form-check-input text-info">
                        Manter
                        <i class="input-helper"></i>
                      </label> -->
                        </div>
                        <a
                          href="javascript:void(0);"
                          class="auth-link text-black"
                          >Esqueceu a senha?</a
                        >
                      </div>
                      <div class="text-center mt-4 font-weight-light">
                        Não estás cadastrado?
                        <router-link to="/auth/register" class="text-danger"
                          >Cadastre-se</router-link
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4"></div>
              </div>
            </div>
            <p></p>
          <!-- </card> -->
        </div>
      </fade-transition>
    </div>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import firebase from "../../firebase";

export default {
  name: "login",
  data() {
    return {
      db: firebase.ref("/cliente"),
      user: {
        username: null,
        password: null,
      },
    };
  },
  methods: {
    login() {
      let isAprovado = false;

      this.db.on("value", (snap) => {
        snap.forEach((item) => {
          let key = item.key;
          let data = item.val();
          if (
            data.perfil.email === this.user.username &&
            data.perfil.senha === this.user.password &&
            data.perfil.status === "aprovado"
          ) {
            this.user.username = "";
            this.user.password = "";

            this.$session.start();
            this.$session.set("nome", data.perfil.nome);
            this.$session.set("email", data.perfil.email);
            this.$session.set("phone", data.perfil.phone);
            this.$session.set("regiao", data.perfil.regiao);
            this.$session.set("key", key);

            this.$router.push("/dashboard");
            isAprovado = true;
            return;
          }
        });

        if (isAprovado == false) {
          this.$swal(
            "Falha",
            "Esta conta não é reconhecida, por favor entre em contato para a validação do seu registo.",
            "error"
          );
        }
      });
    },
  },
};
</script>

<style>
.login-bg{
  /* background-image: url(../images/lockscreen-bg.jpeg); */
  background: url(../../assets/images/lockscreen-bg.jpeg) no-repeat center center fixed;
  position: fixed;
  top: 0;
  left: 0;

  /* Preserve aspet ratio */
  min-width: 100%;
  min-height: 100%;
}
.cartao {
  border: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.chat {
  margin-top: auto;
  margin-bottom: auto;
}
.card {
  height: 50%;
  width: 100%;
  padding: 14px;
  border-radius: 15px !important;
  border-bottom-color: #d48919;
  background-color: rgb(255, 255, 255) !important;
}
.cardd {
  height: 50%;
  width: 100%;
  padding: 14px;
  border-radius: 15px !important;
  border-bottom-color: #000000;
  background-color: rgb(255, 255, 255) !important;
}
.contacts_body {
  padding: 0.75rem 0 !important;
  overflow-y: auto;
  white-space: nowrap;
}
.msg_card_body {
  overflow-y: auto;
}
.card-header {
  border-radius: 15px 15px 0 0 !important;
  border-bottom: 0 !important;
}
.card-footer {
  border-radius: 0 0 15px 15px !important;
  border-top: 0 !important;
}
.container {
  align-content: center;
}
.search {
  border-radius: 15px 0 0 15px !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
}
.search:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.type_msg {
  background-color: rgba(253, 253, 253, 0.3) !important;
  border: 0 !important;
  color: rgb(0, 0, 0) !important;
  font-size: 20px;
  font-weight: bold;
  height: 60px !important;
  overflow-y: auto;
}
.type_msg:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.attach_btn {
  border-radius: 15px 0 0 15px !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
  cursor: pointer;
}
.send_btn {
  border-radius: 0 15px 15px 0 !important;
  background-color: rgb(255, 255, 255) !important;
  border: 0 !important;
  border-color: #ffffff;
  color: rgb(0, 0, 0) !important;
  cursor: pointer;
}
.search_btn {
  border-radius: 0 15px 15px 0 !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
  cursor: pointer;
}
.contacts {
  list-style: none;
  padding: 0;
}
.contacts li {
  width: 100% !important;
  padding: 5px 10px;
  margin-bottom: 15px !important;
}
.active {
  background-color: rgba(0, 0, 0, 0.3);
}
.user_img {
  height: 70px;
  width: 70px;
  border: 1.5px solid #f5f6fa;
}
.user_img_msg {
  height: 40px;
  width: 40px;
  border: 1.5px solid #f5f6fa;
}
.img_cont {
  position: relative;
  height: 70px;
  width: 70px;
}
.img_cont_msg {
  height: 40px;
  width: 40px;
}
.online_icon {
  position: absolute;
  height: 15px;
  width: 15px;
  background-color: #4cd137;
  border-radius: 50%;
  bottom: 0.2em;
  right: 0.4em;
  border: 1.5px solid white;
}
.offline {
  background-color: #c23616 !important;
}
.user_info {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
}
.user_info span {
  font-size: 20px;
  color: white;
}
.user_info p {
  font-size: 10px;
  color: rgba(255, 255, 255, 0.6);
}
.video_cam {
  margin-left: 50px;
  margin-top: 5px;
}
.video_cam span {
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin-right: 20px;
}
.msg_cotainer {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  border-radius: 25px;
  background-color: #82ccdd;
  padding: 10px;
  padding-right: 70px;
  position: relative;
}
.msg_cotainer_send {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
  border-radius: 25px;
  background-color: #78e08f;
  padding: 10px;
  padding-left: 70px;
  position: relative;
}
.msg_time {
  position: absolute;
  left: 0;
  bottom: -15px;
  color: rgb(0, 0, 0);
  font-size: 12px;
}
.msg_time_send {
  padding-top: 15px;
  position: absolute;
  right: 0;
  bottom: -15px;
  color: rgb(0, 0, 0);
  font-size: 12px;
}
.msg_head {
  position: relative;
}
#action_menu_btn {
  position: absolute;
  right: 10px;
  top: 10px;
  color: white;
  cursor: pointer;
  font-size: 20px;
}
.action_menu {
  z-index: 1;
  position: absolute;
  padding: 15px 0;
  background-color: rgb(119, 118, 105);
  color: white;
  border-radius: 15px;
  top: 30px;
  right: 15px;
  display: none;
}
.action_menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.action_menu ul li {
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 5px;
}
.action_menu ul li i {
  padding-right: 10px;
}
.action_menu ul li:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.973);
}
@media (max-width: 576px) {
  .contacts_card {
    margin-bottom: 15px !important;
  }
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #7f7fd5;
  border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5454b6;
}
</style>
