<template>


<!-- Body -->
            <div>
                    <div class="table-full-width table-responsive">
                      <LogisticaHistoricoNaoEntregueList></LogisticaHistoricoNaoEntregueList>
                    </div>
            </div>




</template>
<script>
  import LogisticaHistoricoNaoEntregueList from './Dashboard/LogisticaHistoricoNaoEntregueList.vue';

  export default {
    components: {
      LogisticaHistoricoNaoEntregueList,
    },
    data() {
      return {
      }
    },
    computed: {

    },
    methods: {
    },
    mounted() {
      this.i18n = this.$i18n;
      if (this.enableRTL) {
        this.i18n.locale = 'ar';
        this.$rtl.enableRTL();
      }
      this.initBigChart(0);
    },
  };
</script>
<style>
</style>
