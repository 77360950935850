<template>
  <div class="">

    <div class="card-body all-icons">
      <div class="">
    <div class="row justify-content-center ">

      <div class="col-md-12 col-xl-12 ">
        <div class="card">
          <div class="card-header msg_head" style="color:#000000; background-color:#ee0556;">
            <div class="d-flex bd-highlight">

              <div class="user_info">
                <span>Em conversa com {{nomeDriver}}</span>
                <p v-if="totalChat===1" style="color:#ffffff;">{{totalChat}} Mensagem</p>
                <p v-else style="color:#ffffff;">{{totalChat}} Mensagens</p>
              </div>

            </div>
            <span id="action_menu_btn"><i class="fas fa-ellipsis-v"></i></span>
            <div class="action_menu ">
              <ul>
                <li @click="goToPerfilDriver"><i class="fas fa-user-circle"></i> Perfil</li>
                <!-- <li><i class="fas fa-users"></i> XYZ2</li>
                <li><i class="fas fa-plus"></i> Adicionar ficheiro</li> -->
                <li
                  data-bs-toggle="modal"
                  data-bs-target="#denuncia"
                ><i class="fas fa-ban"></i> Denunciar</li>
              </ul>
            </div>
          </div>

          <div class="card-body msg_card_body">

            <div v-for="(chat,index) in chats" :key="index">
              <div v-if="chat.type_user === 'driver'" class="d-flex justify-content-start mb-4">
                <div class="img_cont_msg">
                  <img src="https://therichpost.com/wp-content/uploads/2020/06/avatar2.png" class="rounded-circle user_img_msg">
                </div>
                <div class="msg_cotainer ">
                  {{chat.message}}
                  <span class="msg_time">{{chat.date}} </span>
                </div>
              </div>
              <div v-else class="d-flex justify-content-end mb-4">
                <div class="msg_cotainer_send">
                  {{chat.message}}
                  <span class="msg_time_send">{{chat.date}}</span>
                </div>
                <div class="img_cont_msg">
                  <img src="https://therichpost.com/wp-content/uploads/2020/06/avatar2.png" class="rounded-circle user_img_msg">
                </div>
              </div>
            </div>

          </div>

          <div class="card-footer">
            <div class="input-group ">
              <div class="input-group-append">
              </div>
              <textarea v-model="chat.message" class="form-control type_msg text-white"  placeholder="Escreva a mensagem aqui..."></textarea>
              <div class="input-group-append">
                <span @click="sendMessage" class="input-group-text send_btn text-white"> &nbsp;&nbsp;  Enviar &nbsp;&nbsp;   <i class="fas fa-location-arrow"></i></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    </div>

    <div  class="modal fade" id="denuncia" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel" >Denunciar <strong>{{nomeDriver}}</strong></h5>
          </div>
          <div class="modal-body">
            <div class="row">
              <base-input label="Motivo" v-model="denuncia.motivo" placeholder="Ex: Falta de correspondência..."> </base-input>
            </div>
          </div>
          <div class="modal-footer">
            <div class="row">
              <button class="btn btn-danger " type="button" data-bs-dismiss="modal"><i class="fas fa-times"></i></button>
              <button  @click="sendDenuncia" class="btn btn-dark ml-2 ms-2"><i class="fa fa-check" aria-hidden="true"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>
<script>

// Chat
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import 'jquery/dist/jquery.min.js';
import $ from 'jquery';
import firebase from "../../firebase";
var FCM = require('fcm-node');

export default {
  data(){
    return{
      dbDriver:firebase.ref("/motorista"),
      dbChat:firebase.ref("/chat"),
      dbDenuncia:firebase.ref("/denuncia"),
      nomeDriver:null,
      tokenDeviceDriver:null,
      chat: {
        read: 'false',
        date:null,
        message:null,
        type_user: 'rider',
        logisticaKey: null
      },
      chats:[],
      totalChat:null,

      denuncia:{
        nome_usuario: this.$session.get('nome'),
        nome_motorista: null,
        logisticaKey:this.$route.params.logisticaKey,
        motoristaKey:this.$route.params.driverId,
        usuarioKey:this.$session.get('key'),
        motivo:null,
        date:this.currentDateTime(),
        status: 'Não visto'
      }
    }
  },
  methods:{

    // Notification FCM
    sentNotification (deviceToken, message) {
      var serverKey = 'AAAAZZEIerk:APA91bE5y4deGEp3bwB5R7r7EoZcJ3U-rcDWTtb-mX-3XEE-04F2OmfowhnFXOsaTodvSOJREKp6GqfKxWmQtqea1C_jlAJ1g7Nmo4GiDwretXjCHtkVFF3WJjoz1RmM3ODq1Fu_5ZH9'; //put your server key here
      var fcm = new FCM(serverKey);

      var message = {
        to: deviceToken,
        collapse_key: 'your_collapse_key',

        notification: {
            title: this.$session.get('nome'),
            body: message
        },

        data: {
            my_key: 'my value',
            my_another_key: 'my another value'
        }
     };

      fcm.send(message, function(err, response){
          if (err) {
              console.log("Erro ao enviar a NOTIFICAÇÃO!");
          } else {
              console.log("Notificação enviada com sucesso:  ", response);
          }
      });

    },

    goToPerfilDriver() {
      this.$router.push('/perfil-do-motorista/'+this.$route.params.driverId)
    },

    getDriver(){
     // console.log('clicado')
      this.dbDriver.child(this.$route.params.driverId).on('value',(snap)=>{
        let data = snap.val();
        this.nomeDriver = data.perfil.nome
        this.tokenDeviceDriver = data.perfil.device_token
       // console.log(this.tokenDeviceDriver)
      });
    },

     getChat(){
      this.getDriver()
      this.dbChat.once('value',(snap)=>{
        let chat_ = []

        snap.forEach((item) => {
          // let key = item.key;
          let dataChat = item.val()
          console.log(dataChat)
          if(dataChat.logisticaKey === this.$route.params.logisticaKey){
            chat_.push({
            date: dataChat.date.substring(0,16),
            message: dataChat.message,
            type_user: dataChat.type_user,
          });
          }
        });
        this.chats = chat_;
        this.totalChat = chat_.length;
      });
    },


    sendMessage(){
      this.chat.date = this.currentDateTime()
      this.chat.logisticaKey = this.$route.params.logisticaKey
      //  var goChat = this.dbChat.child().push().key();
     this.dbChat.push().set(this.chat)
      .then(()=>{
        this.getChat()
        // Send Notification
        this.sentNotification(this.tokenDeviceDriver, this.chat.message);
        this.chat.message=''
      })
      .catch((error)=>{
        this.$swal(
          'Erro',
          'Tente novamente,'+error,
          'error')
      })
    },

    sendDenuncia(){
      // this.denuncia.date = this.currentDateTime()
      this.denuncia.nome_motorista = this.nomeDriver,

      this.dbDenuncia.child(this.$route.params.logisticaKey).set(this.denuncia)
      .then((result)=>{
        this.denuncia.motivo=''
        this.$swal(
          'Sucesso',
          'A sua denúncia foi enviada.',
          'success')

          location.reload();
      })
      .catch((error)=>{
        this.$swal(
          'Erro',
          'Tente novamente,'+error,
          'error')
      })
    },

    currentDateTime() {
      var current = new Date();
      const date = current.getFullYear() + '-' + this.verificar(current.getMonth()+1) + '-'+this.verificar(current.getDate());
      const time = this.verificar(current.getHours()) + ":" + this.verificar(current.getMinutes()) + ":" + this.verificar(current.getSeconds())
      const dateTime = date +' '+ time;

      return dateTime;
    },
    verificar(value){
      if(parseInt(value)<10)
        value = '0'+value
      return value
    }

  },

  mounted(){
    this.getDriver()
    this.getChat()

    $('#action_menu_btn').click(function(){
      $('.action_menu').toggle();
    });
  }
};
</script>


<style>
.chat{
  margin-top: auto;
  margin-bottom: auto;
}
.card{
  height: 600px;
  width: 90%;
  border-radius: 15px !important;
  border-bottom-color: #d48919;
  background-color: rgb(255, 255, 255) !important;
}
.contacts_body{
  padding:  0.75rem 0 !important;
  overflow-y: auto;
  white-space: nowrap;
}
.msg_card_body{
  overflow-y: auto;
}
.card-header{
  border-radius: 15px 15px 0 0 !important;
  border-bottom: 0 !important;
}
.card-footer{
border-radius: 0 0 15px 15px !important;
  border-top: 0 !important;
}
.container{
  align-content: center;
}
.search{
  border-radius: 15px 0 0 15px !important;
  background-color: rgba(0,0,0,0.3) !important;
  border:0 !important;
  color:white !important;
}
.search:focus{
   box-shadow:none !important;
 outline:0px !important;
}
.type_msg{
  background-color: rgba(253, 253, 253, 0.3) !important;
  border:0 !important;
  color:rgb(0, 0, 0) !important;
  font-size: 20px;
  font-weight: bold;
  height: 60px !important;
  overflow-y: auto;
}
  .type_msg:focus{
   box-shadow:none !important;
 outline:0px !important;
}
.attach_btn{
border-radius: 15px 0 0 15px !important;
background-color: rgba(0,0,0,0.3) !important;
  border:0 !important;
  color: white !important;
  cursor: pointer;
}
.send_btn{
border-radius: 0 15px 15px 0 !important;
background-color: rgb(255, 255, 255) !important;
  border:0 !important;
  border-color: #ffffff;
  color: rgb(0, 0, 0) !important;
  cursor: pointer;
}
.search_btn{
  border-radius: 0 15px 15px 0 !important;
  background-color: rgba(0,0,0,0.3) !important;
  border:0 !important;
  color: white !important;
  cursor: pointer;
}
.contacts{
  list-style: none;
  padding: 0;
}
.contacts li{
  width: 100% !important;
  padding: 5px 10px;
  margin-bottom: 15px !important;
}
.active{
  background-color: rgba(0,0,0,0.3);
}
.user_img{
  height: 70px;
  width: 70px;
  border:1.5px solid #f5f6fa;
}
.user_img_msg{
  height: 40px;
  width: 40px;
  border:1.5px solid #f5f6fa;
}
.img_cont{
  position: relative;
  height: 70px;
  width: 70px;
}
.img_cont_msg{
  height: 40px;
  width: 40px;
}
.online_icon{
position: absolute;
height: 15px;
width:15px;
background-color: #4cd137;
border-radius: 50%;
bottom: 0.2em;
right: 0.4em;
border:1.5px solid white;
}
.offline{
background-color: #c23616 !important;
}
.user_info{
margin-top: auto;
margin-bottom: auto;
margin-left: 15px;
}
.user_info span{
font-size: 20px;
color: white;
}
.user_info p{
font-size: 10px;
color: rgba(255,255,255,0.6);
}
.video_cam{
margin-left: 50px;
margin-top: 5px;
}
.video_cam span{
color: white;
font-size: 20px;
cursor: pointer;
margin-right: 20px;
}
.msg_cotainer{
margin-top: auto;
margin-bottom: auto;
margin-left: 10px;
border-radius: 25px;
background-color: #82ccdd;
padding: 10px;
padding-right: 80px;
position: relative;
}
.msg_cotainer_send{
margin-top: auto;
margin-bottom: auto;
margin-right: 10px;
border-radius: 25px;
background-color: #78e08f;
padding: 10px;
padding-left: 80px;
position: relative;
}
.msg_time{
position: absolute;
left: 0;
bottom: -15px;
color: rgb(0, 0, 0);
font-size: 12px;
}
.msg_time_send{
padding-top: 15px;
position: absolute;
right:0;
bottom: -15px;
color: rgb(0, 0, 0);
font-size: 12px;
}
.msg_head{
position: relative;
}
#action_menu_btn{
position: absolute;
right: 10px;
top: 10px;
color: white;
cursor: pointer;
font-size: 20px;
}
.action_menu{
z-index: 1;
position: absolute;
padding: 15px 0;
background-color: rgb(119, 118, 105);
color: white;
border-radius: 15px;
top: 30px;
right: 15px;
display: none;
}
.action_menu ul{
list-style: none;
padding: 0;
margin: 0;
}
.action_menu ul li{
width: 100%;
padding: 10px 15px;
margin-bottom: 5px;
}
.action_menu ul li i{
padding-right: 10px;
}
.action_menu ul li:hover{
cursor: pointer;
background-color: rgba(0, 0, 0, 0.973);
}
@media(max-width: 576px){
.contacts_card{
margin-bottom: 15px !important;
}
}
/* width */
::-webkit-scrollbar {
width: 10px;
}
/* Track */
 ::-webkit-scrollbar-track {
box-shadow: inset 0 0 5px grey;
border-radius: 10px;
}
/* Handle */
 ::-webkit-scrollbar-thumb {
background: #7F7FD5;
border-radius: 10px;
}
/* Handle on hover */
 ::-webkit-scrollbar-thumb:hover {
background: #5454b6;
}

</style>
