<template>
  <div class="">
    <div class="card-body all-icons">
      <div class="">
        <div class="row justify-content-center">
          <div class="col-md-12 col-xl-12">
            <div class="card">
              <div
                class="card-header msg_head"
                style="color: #000000; background-color: #ee0556"
              >
                <div class="d-flex bd-highlight">
                  <div class="user_info">
                    <span
                      >({{ total - 1 }}) Serviços Parcialmente Entregues --- Custo:
                      {{ custoTotal }}€</span
                    >
                    <p></p>
                  </div>
                </div>
              </div>
              <div class="card-body msg_card_body">
                <!-- Body -->
                <div>
                  <div class="table-full-width table-responsive">
                    <div>
                      <div class="row">
                        <!-- <h6 class="title d-inline col-9" style="color:#000000; background-color:#ee0556;">Logisticas concluídas- ({{total}})</h6><br> -->
                      </div>
                      <br />

                      <div style="width: 100%" class="card-body">
                        <vue-table-dynamic :params="params" ref="table">
                          <template
                            class="text-center"
                            v-slot:column-3="{ props }"
                          >
                            <button
                              @click="openModal(props.cellData)"
                              data-bs-toggle="modal"
                              data-bs-target="#historico"
                              title="Ver o serviço"
                              class="text-center btn btn-outline-info"
                            >
                              <i class="fas fa-eye"></i>
                            </button>
                            <button
                              @click="openModalNotaFiscal(props.cellData)"
                              data-bs-toggle="modal"
                              data-bs-target="#fiscal"
                              class="text-center btn btn-outline-dark"
                              title="Ver a Imagem"
                            >
                              <i class="fas fa-file"></i>
                            </button>
                          </template>
                          <!-- <template v-slot:column-1="{ props }">
          <span>Slot::{{props.cellData}}--{{props.row}}--{{props.column}}</span>
        </template> -->
                        </vue-table-dynamic>
                      </div>

                      <base-table
                        :data="logisticas"
                        thead-classes="text-primary"
                        class="
                          table table-sm
                          small
                          table-bordered table-table-responsive-lg
                        "
                      >
                        <template slot-scope="{ row }">
                          <td class="td-actions text-right">
                            <i class="tim-icons icon-delivery-fast"></i>
                          </td>
                          <td>
                            <h5 class="title text-info">
                              {{ row.referencia }}
                            </h5>
                            <p
                              v-if="row.status === 'concluido'"
                              class="text-muted"
                            >
                              O executivo {{ row.nome_motorista }} terminou o
                              serviço
                            </p>
                            <p v-else class="text-muted">{{ row.status }}</p>
                          </td>
                          <td class="td-actions text-right">
                            <base-button
                              type="link"
                              @click="getLogistica_(row.key)"
                              aria-label="edit button"
                              data-bs-toggle="modal"
                              data-bs-target="#historico"
                            >
                              <i class="tim-icons icon-double-right"></i>
                            </base-button>

                          </td>
                        </template>
                      </base-table>

                      <!-- Modal view more-->
                      <div
                        class="modal fade"
                        id="historico"
                        tabindex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="exampleModalLabel">
                                <strong
                                  >DETALHES DO SERVIÇO -
                                  {{ logisticaDetalhes.referencia }}
                                </strong>
                              </h5>
                            </div>
                            <div class="modal-body">
                              <p>
                                Serviço
                                <strong>{{ logisticaDetalhes.status }}</strong>
                                em {{ logisticaDetalhes.fim }}.
                              </p>
                              <table class="table table-sm">
                                <tbody>
                                  <tr>
                                    <th scope="row">Recolha:</th>
                                    <td>
                                      {{ logisticaDetalhes.endereco_origem }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Entrega:</th>
                                    <td>
                                      {{ logisticaDetalhes.endereco_destino }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Destinatário:</th>
                                    <td>
                                      {{ logisticaDetalhes.destinatario }} -
                                      {{ logisticaDetalhes.email }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Data de envio:</th>
                                    <td>{{ logisticaDetalhes.data_envio }}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Custo do Serviço:</th>
                                    <td>
                                      {{ logisticaDetalhes.valor_inicial }}
                                    </td>
                                  </tr>
                                  <tr
                                    v-if="
                                      logisticaDetalhes.nome_motorista !=
                                      'aguardando'
                                    "
                                  >
                                    <th scope="row">Motorista:</th>
                                    <td>
                                      {{ logisticaDetalhes.nome_motorista }} -
                                      {{ logisticaDetalhes.token_motorista }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Artigos:</th>
                                    <td>{{ logisticaDetalhes.artigos }}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Motivos:</th>
                                    <td style="color:red;">{{ logisticaDetalhes.avaliacao }}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div class="modal-footer">
                              <button type="button" data-bs-dismiss="modal">
                                <i class="fas fa-times"></i>
                              </button>
                              <!-- <button type="button" class="btn btn-primary mt-3 ms-2">Confirmar</button> -->
                            </div>
                          </div>
                        </div>
                      </div>

                       <!-- Nota Fiscal-->
                      <div
                        class="modal fade"
                        id="fiscal"
                        tabindex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="exampleModalLabel">
                                <strong
                                  >IMAGEM -
                                  {{ logisticaDetalhes.referencia }}
                                </strong>
                              </h5>
                            </div>
                            <div class="modal-body">
                              <div class="row" style="border-radius: 25px;">
                                <img :src='logisticaDetalhes.nota_fiscal' width="100%" height="500px">
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button type="button" data-bs-dismiss="modal">
                                <i class="fas fa-times"></i>
                              </button>
                              <!-- <button type="button" class="btn btn-primary mt-3 ms-2">Confirmar</button> -->
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { BaseTable } from "@/components";
import firebase from "../../firebase";
import VueTableDynamic from 'vue-table-dynamic'
export default {
  components: {
    BaseTable,
    VueTableDynamic
  },

  data() {
    return {
      db: firebase.ref("/logistica"),
      logisticas: [],
      total: 0,
      tipos: ["aguardando", "pendentes", "em execução", "em processo"],
      nome: "em processo",
      logisticaIndex: null,
      logisticaDetalhes: {
        custoTotal: 0,
        avaliacao:null,
        key: null,
        criado_em: null,
        email: null,
        data_envio: null,
        nome_motorista: null,
        periodo_entrega: null,
        endereco_destino: null,
        endereco_origem: null,
        referencia: null,
        fim: null,
        token_motorista: null,
        latitude_origem: null,
        longitude_origem: null,
        valor_inicial: null,
        destinatario: null,
        status: null,
        id_motorista: null,
        pagamento: null,
        nota_fiscal:null,
        artigos: []
      },

      params: {
        data: [
          ['REFERÊNCIA',  'DATA FIM', 'NOME CLIENTE', 'VISUALIZAR']
        ],
        header: 'row',
        enableSearch: true,
        border: true,
        stripe: true,
        pagination: true,
        pageSize: 10,
        pageSizes: [5, 10, 20, 50],
        sort: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
        showCheck: true
      },

    };
  },

  methods: {

    getLogisticasConcluidasNaoPagas() {
      let _total = 0

      this.db.on("value", (snap) => {

        snap.forEach((item) => {
          let key = item.key;
          let data = item.val();
          if(data.status==='parcial_concluido' && data.id_usuario === this.$session.get("key")){
            this.params.data.push([
              data.referencia,
              data.fim,
              data.destinatario,
              key
            ]);
            _total = _total + parseFloat(data.valor_inicial)
          }
        })

      })

      // this.logisticas = _logistics;
      this.total = this.params.data.length;
      this.custoTotal = _total.toFixed(2);
    },


    openModal(key){
       this.db.child(key).on("value", (snap) => {
          let key_ = snap.key;
          let data = snap.val();

          this.logisticaDetalhes.key = key_
          this.logisticaDetalhes.avaliacao = data.avaliacao,
          this.logisticaDetalhes.pagamento = data.pagamento,
          this.logisticaDetalhes.data_envio = data.data_envio,
          this.logisticaDetalhes.distancia = data.distancia,
          this.logisticaDetalhes.duracao = data.duracao,
          this.logisticaDetalhes.endereco_destino = data.endereco_destino,
          this.logisticaDetalhes.endereco_origem = data.endereco_origem,
          this.logisticaDetalhes.tipo = data.tipo,
          this.logisticaDetalhes.token_motorista = data.token_motorista,
          this.logisticaDetalhes.valor_inicial = data.valor_inicial,
          this.logisticaDetalhes.nome_usuario = data.nome_usuario,
          this.logisticaDetalhes.fim = data.fim
          this.logisticaDetalhes.referencia = data.referencia
          this.logisticaDetalhes.criado_em = data.criado_em
          this.logisticaDetalhes.status = data.status
          this.logisticaDetalhes.destinatario = data.destinatario
          this.logisticaDetalhes.email = data.email
          this.logisticaDetalhes.nome_motorista = data.nome_motorista
          this.logisticaDetalhes.artigos = data.artigos
          this.logisticaDetalhes.nota_fiscal = data.nota_fiscal

          this.$refs.modalDetalhes.openModal()
       })
    },

    openModalNotaFiscal(key){
       this.db.child(key).on("value", (snap) => {
          let key_ = snap.key;
          let data = snap.val();

          this.logisticaDetalhes.key = key_
          this.logisticaDetalhes.referencia = data.referencia
          this.logisticaDetalhes.nota_fiscal = data.nota_fiscal

          this.$refs.modalNotaFiscal.openModal()
       })
    },

  },

  mounted() {
    // this.db.on("value", this.getLogistics);
    this.getLogisticasConcluidasNaoPagas();
  }
};
</script>
<style></style>
